import React, { useEffect, useState } from 'react';

import useToast from 'components/widgets/toast/useToast';
import useTransaction from 'hooks/transaction/useTransaction';
import { generateImageUrl } from 'services/url';

import ScanIdVoucherItem from '../scan-id-voucher-item';
import styles from './index.module.scss';

const ScanIdVoucher = ({ onUpdate }) => {
    const { update: updateTransaction, attachedData } = useTransaction();
    const { triggerToast } = useToast();
    const [vouchersList, setVouchersList] = useState([]);

    useEffect(() => {
        if (!attachedData) {
            return;
        }

        const { cards: list } = attachedData ?? { card: [] };

        setVouchersList(
            list.map((item) => ({
                isSelected: item.selected,
                ...item,
            })),
        );
    }, [attachedData]);

    const onChange = (id) => {
        const selected = vouchersList.find((voucher) => voucher.id === id);

        if (!selected) {
            triggerToast("Couldn't find selected voucher!", 'error');

            return;
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const selectedCoupons = getSelectedCoupons(vouchersList);
            // eslint-disable-next-line prefer-const
            let [updatedList, updatedSelectedCouponsCount] = addSelectedVoucher(vouchersList, id);

            if (selectedCoupons.length && updatedSelectedCouponsCount > 1) {
                updatedList = updatedList.map((voucher) => {
                    if (voucher.id === selectedCoupons[0].id) {
                        return {
                            ...voucher,
                            isSelected: false,
                        };
                    }

                    return voucher;
                });
            }

            const selectedVouchers = updatedList.filter((voucher) => voucher.isSelected).map((voucher) => voucher.id);

            const response = await updateTransaction(attachedData?.uuid, selectedVouchers);

            onUpdate(response);

            resolve(response);
        });
    };

    return (
        <div className={styles.voucher_container}>
            {vouchersList
                ? vouchersList.map((voucher) => {
                    const isCoupon = voucher.type === 'COUPON';
                    const image = generateImageUrl(voucher.thumbnail);

                    const currency = voucher.amount.currency.includes('PERCENT') ? '%' : voucher.amount.currency;
                    const price = isCoupon
                        ? `-${ voucher.amount.price }${ currency }`
                        : `${ voucher.amount.price }${ currency }`;

                    let selectedAmount = '';

                    if (voucher.selectedAmount) {
                        const selectedAmountCurrency = voucher.selectedAmount.currency.includes('PERCENT')
                            ? '%'
                            : voucher.selectedAmount.currency;

                        selectedAmount = isCoupon
                            ? `-${ voucher.selectedAmount.price }${ selectedAmountCurrency }`
                            : `${ voucher.selectedAmount.price }${ selectedAmountCurrency }`;
                    }

                    return (
                        <ScanIdVoucherItem
                            key={voucher.id}
                            id={voucher.id}
                            image={image}
                            title={voucher.title}
                            price={price}
                            selectedAmount={selectedAmount}
                            type={voucher.type}
                            onChange={onChange}
                            canUse={voucher.canUse}
                            minimumToUse={voucher.minimumToUse}
                            useCondition={voucher.useCondition}
                            isSelected={voucher.isSelected}
                        />
                    );
                })
                : null}
        </div>
    );
};

const getSelectedCoupons = (vouchersList) => vouchersList.filter((voucher) => voucher.type === 'COUPON' && voucher.isSelected) || [];

const addSelectedVoucher = (vouchersList, id) => {
    const updatedVouchersList = vouchersList.map((voucher) => {
        if (voucher.id === id) {
            return {
                ...voucher,
                isSelected: !voucher.isSelected,
            };
        }

        return voucher;
    });

    const updatedSelectedCouponsCount =
        updatedVouchersList.filter((voucher) => voucher.type === 'COUPON' && voucher.isSelected).length || 0;

    return [updatedVouchersList, updatedSelectedCouponsCount];
};

export default ScanIdVoucher;
