/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
import PrimaryButton from 'components/form/button/PrimaryButton';
import './cacheReporter.scss';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import SecondaryButton from 'components/form/button/SecondaryButton';
import { useState } from 'react';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import { sleep } from 'services/promise';

interface CacheReporterProps {
    shouldShow: boolean;
    onClose: () => void;
    onUpdate: () => void;
}

const CacheReporter = ({ shouldShow, onClose, onUpdate }: CacheReporterProps) => {
    const [isLoading, setLoader] = useState(false);

    const onUpdateStart = () => {
        setLoader(true);

        sleep(1000).then(() => onUpdate());
    };

    return (
        <DraggablePopup show={shouldShow} onClose={onClose}>
            <div className="cache-reporter">
                <h1>MISE À JOUR</h1>
                <p>
                    Une nouvelle version de l'application est disponible. Faites une
                    mise à jour pour une expérience optimale.
                </p>

                <PrimaryButton
                    className="cache-clear"
                    onClick={onUpdateStart}
                >
                    {isLoading ? <Loader fill={LoaderInButtonFillColor} /> : 'Mettre à jour'}
                </PrimaryButton>
                <SecondaryButton
                    onClick={onClose}
                >
                    Annuler
                </SecondaryButton>
            </div>
        </DraggablePopup>
    );
};

export default CacheReporter;
