import { useIntl } from 'react-intl';
import './premiumAdvantages.scss';

const PremiumAdvantages = ({ isSuspended }: { isSuspended?: boolean }) => {
    const intl = useIntl();
    const html = intl.formatMessage({ id: 'premium.overview.paragraph' });
    const suspendedHtml = intl.formatMessage({ id: 'premium.overview.paragraph.suspended' });

    return (
        <>
            {isSuspended && <div className="premium-suspended" dangerouslySetInnerHTML={{ __html: suspendedHtml }}/>}
            <div className="premium-advantages" dangerouslySetInnerHTML={{ __html: html }} />
        </>
    );
};

export default PremiumAdvantages;
