import { logStyles } from 'constants/logs';

type LogBaseTypes = 'info' | 'success' | 'error' | 'warn' | 'debug';

const TypeToStyleMapper: Record<LogBaseTypes, string> = {
    info:    logStyles.infoBg,
    success: logStyles.successBg,
    error:   logStyles.errorBg,
    warn:    logStyles.warnBg,
    debug:   logStyles.debugBg,
};

const logBase = (
    header: string,
    text: string,
    type: LogBaseTypes = 'info',
): void => {
    const logStyle =  TypeToStyleMapper?.[type] ?? TypeToStyleMapper.info;

    console.log(
        `%c${ header }%c ${ text }`,
        `${ logStyles.header }${ logStyle }`,
        logStyles.text,
    );
};

export const LogManger = {
    info:    (header: string, text: string) => logBase(header, text, 'info'),
    success: (header: string, text: string) => logBase(header, text, 'success'),
    error:   (header: string, text: string) => logBase(header, text, 'error'),
    warn:    (header: string, text: string) => logBase(header, text, 'warn'),
    debug:   (header: string, text: string) => logBase(header, text, 'debug'),
};
