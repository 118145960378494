import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { Checked } from 'components/svg';
import Popup from 'components/widgets/popup';
import useToast from 'components/widgets/toast/useToast';
import { LoaderInButtonFillColor, UnknownValue } from 'constants/defaults';
import useLocale from 'hooks/locale/useLocale';
import useTransaction from 'hooks/transaction/useTransaction';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { generateImageUrl } from 'services/url';
import { ExitPromptContext } from 'app/App';

import styles from './index.module.scss';
import ScanIdVoucher from './scan-id-voucher';

const ScanId = () => {
    const intl = useIntl();
    const lang = useLocale();
    const { triggerToast } = useToast();
    const { attachedData, buy, deletePendingSellerConfirmation } = useTransaction();
    const { setShouldShowExitPrompt } = useContext(ExitPromptContext);

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionData, setTransactionData] = useState(attachedData);
    const [finalResults, setFinalResults] = useState(null);

    useEffect(() => {
        setShouldShowExitPrompt(true);
        setTransactionData(attachedData);

        return () => {
            setShouldShowExitPrompt(false);
            deletePendingSellerConfirmation()
        };
    }, [attachedData]);

    const onUpdate = (data) => setTransactionData(data);

    const { logo, sellPrice, cashAmount } = useMemo(() => {
        if (!transactionData) {
            return { logo: null, sellPrice: null, cashAmount: null };
        }

        return {
            logo:       transactionData?.logo ? generateImageUrl(attachedData.logo) : null,
            sellPrice:  `${ transactionData?.amount?.price }${ transactionData?.amount?.currency }` || UnknownValue,
            cashAmount: `${ transactionData?.cashAmount?.price }${ transactionData?.cashAmount?.currency }`,
        };
    }, [transactionData]);

    const confirmPayment = async () => {
        setIsLoading(true);

        if (!transactionData || !transactionData.uuid) {
            setIsLoading(false);
            triggerToast(intl.formatMessage({ id: 'scan-id.transaction.verify.transaction-failed' }), 'error');

            return;
        }

        const selectedVouchers = transactionData.cards.filter((card) => card.selected).map((card) => card.id);

        const response = await buy(transactionData.uuid, selectedVouchers);

        if (response && !response?.hasError && response?.status === 'VALID') {
            // setGainedPoints(response?.redeemPoints || response?.points || 0);
            setFinalResults(response || null);
            openPopup();
        }

        setIsLoading(false);
    };

    const closePopup = () => setShowModal(false);
    const openPopup = () => setShowModal(true);

    return transactionData ? (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.scan-id' })} />

            <div className="flex-grow-1">
                <Popup
                    className={styles.popupHeader}
                    show={showModal}
                    shouldCloseOnFocusOut={false}
                    onClose={closePopup}
                >
                    <Checked />
                    <h1>
                        <FormattedMessage id="scan-id.confirm-popup.title" />
                    </h1>

                    <p>
                        <FormattedMessage
                            id="scan-id.confirm-popup.description"
                            values={{ points: finalResults?.redeemPoints || finalResults?.points || 0 }}
                        />
                        <br />
                        <FormattedMessage
                            id="ScanId.you-need-to-pay-cachier"
                            values={{
                                amount: (
                                    <span>
                                        {`${ finalResults?.cashAmount?.price || '0' }${
                                            finalResults?.cashAmount?.currency || 'DT'
                                        }`}
                                    </span>
                                ),
                            }}
                        />
                    </p>

                    <Link to={All_PATHS_NAMESPACES[lang].home.path}>
                        <FormattedMessage id="scan-id.confirm-popup.redirect-link" />
                    </Link>
                </Popup>

                <div className={styles.image_wrapper}>
                    <Img src={logo} alt="company logo" />
                </div>
                <div className={styles.transaction_description_item}>
                    <div className={styles.item_header}>
                        <FormattedMessage id="popup.transacgtion-price" />
                    </div>
                    <div className={styles.price}>{sellPrice}</div>
                </div>
                <div className={styles.transaction_description_item}>
                    <div className={styles.item_header}>
                        <FormattedMessage id="popup.seller-name" />
                    </div>
                    <div className={styles.item_details}>{transactionData.seller}</div>
                </div>
                <div className={styles.transaction_description_item}>
                    <div className={styles.item_header}>
                        <FormattedMessage id="scan.popup.store" />
                    </div>
                    <div className={styles.item_details}>{transactionData.store}</div>
                </div>
                <div className={styles.transaction_description_item}>
                    <div className={styles.item_header}>
                        <FormattedMessage id="popup.transaction-code" />
                    </div>
                    <div className={styles.item_details}>{transactionData.uuid}</div>
                </div>
                <div className={styles.bar} />
                <h5>
                    <FormattedMessage id="ScanId.payment-method" />
                </h5>
                <p className={styles.details}>
                    <FormattedMessage id="ScanId.select-transaction-that-you-want-to-use-in-this-transaction" />
                </p>

                {transactionData?.cards ? <ScanIdVoucher onUpdate={onUpdate} /> : <Loader />}

                {/* <p className={styles.payment_amount}>
                    <FormattedMessage
                        id="ScanId.you-need-to-pay-cachier"
                        values={{
                            amount: <span> {cashAmount} </span>,
                        }}
                    />
                </p> */}

                <button
                    type="button"
                    disabled={isLoading}
                    className={`${ styles.confirm_payment } btn-lg-primary`}
                    onClick={confirmPayment}
                >
                    {isLoading ? (
                        <Loader fill={LoaderInButtonFillColor} />
                    ) : (
                        <FormattedMessage id="ScanId.confirm-payment" />
                    )}
                </button>
            </div>
        </PageTransitionWrapper>
    ) : (
        <Loader />
    );
};

export default ScanId;
