import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { Alert } from 'components/svg/Alert';

import styles from './index.module.scss';

const ScanIdVoucherItem = ({
    type,
    id,
    image,
    title,
    price,
    selectedAmount = null,
    isSelected,
    onChange = null,
    onClick = null,
    useClick = false,
    canUse, // if false,
    minimumToUse = null,
    useCondition, // show tooltip
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        if(useClick) {
            onClick?.(id)

            return;
        }

        setIsLoading(true);

        setTimeout(() => {
            onChange?.(id)?.then(() => setIsLoading(false));
        }, 100);
    };

    return (
        <div
            className={`${ styles['voucher-item-container'] } ${ isSelected ? styles.selected : styles.circle }`}
            onClick={(e) => (!canUse ? null : handleClick(e))}
        >
            {isLoading ? (
                <div className={styles['loader-wrapper']}>
                    <Loader />
                </div>
            ) : (
                <span />
            )}

            <div className={styles.container}>
                {useCondition && (
                    <div className={styles.useCondition}>
                        <Alert fill="white" />

                        <motion.div initial={{ x: 10, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
                            <p>{useCondition}</p>
                        </motion.div>
                    </div>
                )}
                <div className={`${ styles.leftArrow } ${ styles['svg-wrapper'] }`}>
                    <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.194 11.6856C19.6488 12.8856 19.6488 15.1144 18.194 16.3144L4.90886 27.2718C2.95214 28.8856 -1.50794e-06 27.4938 -1.53819e-06 24.9574L-1.79952e-06 3.04261C-1.82976e-06 0.506207 2.95213 -0.88563 4.90885 0.728246L18.194 11.6856Z"
                            fill="#0E1118"
                        />
                    </svg>
                </div>
                <div className={`${ styles.rightArrow } ${ styles['svg-wrapper'] }`}>
                    <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.80601 16.3144C0.351154 15.1144 0.351154 12.8856 1.80601 11.6856L15.0911 0.728244C17.0479 -0.885632 20 0.506202 20 3.04261L20 24.9574C20 27.4938 17.0479 28.8856 15.0911 27.2718L1.80601 16.3144Z"
                            fill="#0E1118"
                        />
                    </svg>
                </div>

                <div className="d-flex flex-column">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            opacity: !canUse ? 0.5 : 1,
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <div className={styles['image-wrapper']}>
                                <Img src={image} />
                            </div>
                            <h6 className="ml-4 m-0">{title}</h6>
                        </div>

                        <div className={styles.amountContainer}>
                            <div className={styles.amount}>
                                <h5 className="m-0">{price}</h5>
                            </div>

                            <div className={styles.selectedAmount}>
                                {selectedAmount && <p className="m-0">{selectedAmount}</p>}
                            </div>
                        </div>
                    </div>

                    {!canUse && (
                        <p className={styles.cantUse}>
                            <FormattedMessage
                                id="scan-id.select-voucher.cant-use"
                                values={{
                                    price: `${ minimumToUse?.price }${ minimumToUse?.currency }`,
                                }}
                            />
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ScanIdVoucherItem;
