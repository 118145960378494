import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

import styles from './index.module.scss';

const getPathToProfilePageTitleMapper = (lang) => ({
    [All_PATHS_NAMESPACES[lang].expired_wallet.path]: 'go-back-layout.expired-wallet-page.title',
});
const loadFeatures = () => import('services/framer-motion/domAnimation').then((res) => res.default);

function GoBackHeader({ backTo }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const lang = useLocale();
    const pathToProfilePageTitleMapper = getPathToProfilePageTitleMapper(lang);

    const handleProfileNavigation = () => {
        navigate(backTo || -1, { preventScrollReset: true });
    };

    const pageTitle = useMemo(() => {
        const result = pathToProfilePageTitleMapper?.[pathname] || '';

        return result;
    }, [pathname]);

    return (
        <div className={`${ styles.app_header } app-header d-flex pos-relative align-items-center`}>
            <div onClick={handleProfileNavigation} className={`${ styles.svg_container } profile-container`}>
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none">
                    <path
                        d="M0.806143 11.0743C0.806143 11.3219 0.905178 11.5695 1.10327 11.7676L9.17565 20.781C9.57184 21.2267 10.3147 21.2762 10.7604 20.88C11.2061 20.4838 11.2557 19.741 10.8595 19.2953L3.4309 11.0743L10.8595 2.85335C11.2557 2.40763 11.2557 1.66477 10.7604 1.26858C10.2652 0.872393 9.57184 0.872393 9.17565 1.36763L1.10327 10.3314C0.905178 10.48 0.806143 10.7772 0.806143 11.0743Z"
                        fill="white"
                    />
                </svg>
            </div>
            <AnimatePresence exitBeforeEnter>
                {pageTitle && (
                    <LazyMotion features={loadFeatures}>
                        <m.h2
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -10 }}
                        >
                            <FormattedMessage id={pageTitle} />
                        </m.h2>
                    </LazyMotion>
                )}
            </AnimatePresence>
        </div>
    );
}

export default GoBackHeader;
