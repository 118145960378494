import { Link } from 'react-router-dom';

import { Taoo } from 'components/svg';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

// import pathRedirection from 'services/utils/pathRedirection';
import styles from './index.module.scss';

function InstructionHeader() {
    const lang = useLocale();

    // const navigate = useNavigate();
    // const [select, setSelect] = useState(false);

    // const handleClick = () => {
    //     setSelect(!select);
    // };

    // const handleLanguageSwitch = (selectedLanguage) => {
    //     setSelect(false);
    //     navigate(pathRedirection(selectedLanguage, lang));
    // };

    return (
        <div className={`${ styles.container } pos-relative`}>
            <Link to={All_PATHS_NAMESPACES[lang].home.path} className={styles.wrapper}>
                <Taoo />
            </Link>
            {/* <div className="language-switcher-container">
                <div
                    className={`languages-drop-down ${
                        select ? 'selected' : ''
                    }`}
                >
                    <span
                        style={{ minWidth: '100%' }}
                        onClick={handleClick}
                        className="lang-item d-flex align-items-center justify-content-end"
                    >
                        {lang === 'ar' && <span>ع</span>}
                        {lang === 'en' && <span>En</span>}
                        {lang === 'fr' && <span>Fr</span>}
                    </span>
                    <div className="drop-down">
                        {Object.keys(All_PATHS_NAMESPACES).map((
                            el, i,
                        ) => {
                            if (el === lang) {
                                return <React.Fragment key={i} />;
                            }

                            if (el !== lang) {
                                return (
                                    <span
                                        onClick={() => handleLanguageSwitch(el)}
                                        key={i}
                                        className="lang-item d-flex align-items-center justify-content-end"
                                    >
                                        {el === 'ar' && <span>ع</span>}
                                        {el === 'en' && <span>En</span>}
                                        {el === 'fr' && <span>Fr</span>}
                                    </span>
                                );
                            }
                        })}
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default InstructionHeader;
