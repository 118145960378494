import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import taooCoin from 'assets/Images/taoo-coin.png';
import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { endpoints } from 'constants/api';
import useLocale from 'hooks/locale/useLocale';
import TaooServerClient, { generateNewCancelTokenSource, isRequestCanceled } from 'http/axios';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { setPoints } from 'store/points/action';

import styles from './index.module.scss';

const getPathToProfilePageTitleMapper = (lang) => ({
    [All_PATHS_NAMESPACES[lang].points_history.path]:      'profile-layout.points-history-page.title',
    [All_PATHS_NAMESPACES[lang].referrals.path]:           'profile-layout.referrals-page.title',
    [All_PATHS_NAMESPACES[lang].edit_profile.path]:        'profile-layout.edit-profile-page.title',
    [All_PATHS_NAMESPACES[lang].edit_tags.path]:           'profile-layout.edit-tags-page.title',
    [All_PATHS_NAMESPACES[lang].levels.path]:              'profile-layout.levels-page.title',
    [All_PATHS_NAMESPACES[lang].order_history.path]:       'profile-layout.order-history-page.title',
    [All_PATHS_NAMESPACES[lang]['help-and-contact'].path]: 'profile-layout.helper-and-contact-page.title',
});
const loadFeatures = () => import('services/framer-motion/domAnimation').then((res) => res.default);

function ProfileHeader({ points, setPoints }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const lang = useLocale();

    const [pointsIsLoading, setPointsIsLoading] = useState(false);
    const pathToProfilePageTitleMapper = getPathToProfilePageTitleMapper(lang);
    const pageTitle = useMemo(() => {
        const result = pathToProfilePageTitleMapper?.[pathname] || '';

        return result;
    }, [pathname]);

    useEffect(() => {
        if (pointsIsLoading) {
            return;
        }

        setPointsIsLoading(true);

        const source = generateNewCancelTokenSource();

        TaooServerClient.get(endpoints.points.getPoints, {
            cancelToken: source.token,
        })
                .then((res) => {
                    if (!res) {
                        return;
                    }

                    setPoints(res.data.points);
                })
                .catch((err) => {
                    if (isRequestCanceled(err)) {
                        console.error('Request canceled!');
                    } else {
                        console.error(err);
                    }
                })
                .finally(() => setPointsIsLoading(false));

        return () => source.cancel();
    }, []);

    const handleProfileNavigation = () => {
        if(pathname === All_PATHS_NAMESPACES[lang].profile.path) {
            navigate(All_PATHS_NAMESPACES[lang].home.path)

            return;
        }

        navigate(-1);
    };

    const toPointsHistory = () => {
        if (pathname !== All_PATHS_NAMESPACES[lang].points_history.path) {
            navigate(All_PATHS_NAMESPACES[lang].points_history.path);
        }
    };

    return (
        <div
            // eslint-disable-next-line max-len
            className={`${ styles.app_header } app-header d-flex align-items-center justify-content-between pos-relative`}
        >
            <div className="d-flex align-items-center">
                <div
                    onClick={handleProfileNavigation}
                    className={`${ styles.svg_container } profile-container mr-3`}
                >
                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none">
                        <path
                            d="M0.806143 11.0743C0.806143 11.3219 0.905178 11.5695 1.10327 11.7676L9.17565 20.781C9.57184 21.2267 10.3147 21.2762 10.7604 20.88C11.2061 20.4838 11.2557 19.741 10.8595 19.2953L3.4309 11.0743L10.8595 2.85335C11.2557 2.40763 11.2557 1.66477 10.7604 1.26858C10.2652 0.872393 9.57184 0.872393 9.17565 1.36763L1.10327 10.3314C0.905178 10.48 0.806143 10.7772 0.806143 11.0743Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {pageTitle && (
                        <LazyMotion features={loadFeatures}>
                            <m.h2
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -10 }}
                            >
                                <FormattedMessage id={pageTitle} />
                            </m.h2>
                        </LazyMotion>
                    )}
                </AnimatePresence>
            </div>
            <div
                onClick={() => toPointsHistory()}
                className={`${ styles.point_container } d-flex align-items-center justify-content-end`}
            >
                <h6 className="mb-0 mx-2">
                    {pointsIsLoading && !points ? <Loader wrapperStyle={{ marginTop: '-2px' }} /> : points}
                </h6>
                <Img src={taooCoin} alt="taoo logo" />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    points: state.points.total,
});

export default connect(mapStateToProps, { setPoints })(ProfileHeader);
