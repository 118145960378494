import classNames from 'classnames';
import { useEffect, useState } from 'react';

// @ts-ignore
import premiumIcon from 'assets/Images/premium.png';
import PremiumOverviewScreen from 'components/premium-screens/premium-overview/PremiumOverviewScreen';
import PremiumStatusScreen from 'components/premium-screens/premium-status/PremiumStatusScreen';
import PremiumSuspendedScreen from 'components/premium-screens/premium-suspended/PremiumSuspendedScreen';
import { useAppSelector } from 'hooks/redux/useRedux';
import Loader from 'components/loaders/loader';

import './premiumBadge.scss';
import useGetProfile from 'hooks/profile/useGetProfile';

const PremiumBadge = ({ showBadge = true, shouldShow = false, onClose = () => null }) => {
    const [shouldShowPopup, setShouldShowPopup] = useState(false);
    const [shouldFetchProfileData, setShouldFetchProfileData] = useState(false);

    useEffect(() => setShouldShowPopup(shouldShow), [shouldShow]);

    useGetProfile({ shouldFetch: shouldFetchProfileData, ignoreRefetch: !shouldFetchProfileData });

    const {
        isPremium, isSuspended, premiumAt, isLoading,
    } = useAppSelector((state) => {
        const profile = state.profileReducer.data;

        return {
            isPremium:   profile?.premium ?? false,
            isSuspended: profile?.suspended ?? false,
            premiumAt:   profile?.premiumAt ?? null,
            isLoading:   state.profileReducer.dataIsLoading ?? false,
        };
    });

    const open = () => setShouldShowPopup(true);

    const close = () => {
        setShouldShowPopup(false);
        onClose?.();
    };

    return (
        <>
            {showBadge && (
                <div className={classNames('premium-badge', { isPremium: isPremium && !isSuspended })} onClick={isLoading ? null : open}>
                    {isLoading
                        ? <Loader />
                        : <img src={premiumIcon} alt="premium-icon" /> }
                </div>
            )}

            {!isPremium && (
                <PremiumOverviewScreen
                    shouldShow={shouldShowPopup}
                    onClose={close}
                    onSuccess={() => setShouldFetchProfileData(true)}
                />
            )}
            {isPremium && !isSuspended && (
                <PremiumStatusScreen
                    premiumSince={premiumAt}
                    shouldShow={shouldShowPopup}
                    onClose={close}
                />
            )}
            {isPremium && isSuspended && (
                <PremiumSuspendedScreen
                    premiumSince={premiumAt}
                    shouldShow={shouldShowPopup}
                    onClose={close}
                />
            )}
        </>
    );
};

export default PremiumBadge;
