import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './index.module.scss';

export const PopupVariants = {
    wrapper: {
        hidden:  {},
        visible: {},
        exit:    {},
    },
    backDrop: {
        hidden: {
            opacity: 0,
            scale:   0.5,
        },
        visible: {
            opacity: 0.7,
            scale:   1,
        },
        exit: {
            opacity:    0,
            transition: {
                duration: 0.5,
            },
        },
    },
    content: {
        hidden: {
            y:       50,
            opacity: 0,
        },
        visible: {
            y:       0,
            opacity: 1,
        },
        exit: {
            y:       50,
            opacity: 0,
        },
    },
};

function Popup({
    className = '', show, children, onClose = null, shouldCloseOnFocusOut = true,
}) {
    const [portalContainer, setPortalContainer] = useState(document.getElementById('popup-container'));

    useEffect(
        () => setPortalContainer(document.getElementById('popup-container')), [],
    );

    // prettier-ignore
    return portalContainer
        ? createPortal(
            <AnimatePresence exitBeforeEnter>
                {
                    show
                        ? (
                            <motion.div
                                layout
                                variants={PopupVariants.wrapper}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                className={`${ styles.popupWrapper } ${ className }`}
                            >
                                <motion.div onClick={() => shouldCloseOnFocusOut && onClose?.()} variants={PopupVariants.backDrop} className={styles.backDrop} />
                                <motion.div variants={PopupVariants.content} className={styles.popupContent}>
                                    {children || 'popup content here'}
                                </motion.div>
                            </motion.div>
                        )
                        : null
                }
            </AnimatePresence>,
            portalContainer,
        )
        : null;
}

export default Popup;
