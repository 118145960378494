import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

function ReferralStoreRegistration() {
    const { referralStoreId } = useParams();
    const language = useLocale();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('referralStoreId', referralStoreId);

        navigate(All_PATHS_NAMESPACES[language].sign_up.path);
    }, []);

    return null;
}

export default ReferralStoreRegistration;
