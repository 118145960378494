/* eslint-disable no-nested-ternary */
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PrimaryButton from 'components/form/button/PrimaryButton';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import { WithPagesForwardedActions } from 'components/widgets/draggable-popup/withPages';

import './premiumOverviewScreen.scss';
import PremiumAdvantages from '../advantages/PremiumAdvantages';
import PremiumRegistrationForm from '../forms/registration-form/PremiumRegistrationForm';
import PremiumRegistrationOtpForm from '../forms/registration-opt-form/PremiumRegistrationOtpForm';
import PremiumRegistrationFeedback from '../forms/registration-feedback/PremiumRegistrationFeedback';

const Overview = ({ onJoin }: { onJoin: () => void }) => (
    <section className="premium-overview-screen">
        <div>
            <h1><FormattedMessage id="premium.overview.title" /></h1>

            <PremiumAdvantages />
        </div>

        <PrimaryButton
            className="premium-overview-action"
            type="button"
            onClick={onJoin}
        >
            <FormattedMessage id="premium.overview.button" />
        </PrimaryButton>
    </section>
);

interface PremiumOverviewScreenProps {
    shouldShow: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const PremiumOverviewScreen = ({ shouldShow, onClose, onSuccess }: PremiumOverviewScreenProps) => {
    const pagesRef = useRef<WithPagesForwardedActions>();
    const [shouldShowResult, setShouldShowResult] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [challenge, setChallenge] = useState('');

    const nextPage = () => pagesRef.current?.paginateNext();
    const previousPage = () => pagesRef.current?.paginatePrevious();

    return (
        <DraggablePopup
            show={shouldShow}
            closeOnBackdropClick
            shouldShowHandle={false}
            isDraggable={false}

            // wrapperStyle={{ height: 'fit-content', minHeight: 'unset', maxHeight: '100%' }}
            onClose={onClose}
        >
            <DraggablePopup.PagesContainer
                canSwipeBetweenPages={false}
                ref={pagesRef}
            >
                <Overview key="overview" onJoin={nextPage}/>
                <PremiumRegistrationForm
                    key="subscription"
                    onBack={previousPage}
                    onFinish={(challenge: string) => {
                        setChallenge(challenge);
                        nextPage();
                    }}
                />
                <PremiumRegistrationOtpForm
                    key="otp-validation"
                    challenge={challenge}
                    onBack={previousPage}
                    onFinish={(status: string) => {
                        if (status === 'ACTIVE') {
                            setIsSuccess(true);
                            onSuccess?.();
                        }

                        setShouldShowResult(true);
                        nextPage();
                    }}
                />
                {shouldShowResult && <PremiumRegistrationFeedback
                    key="subscription-fb"
                    type={isSuccess ? 'success' : 'failed'}
                    onFinish={onClose}
                />}
            </DraggablePopup.PagesContainer>
        </DraggablePopup>
    );
};

export default PremiumOverviewScreen;
