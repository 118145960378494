import { Outlet } from 'react-router-dom';

import GoBackHeader from 'components/headers/go-back-header';

function GoBackLayout({ backTo = null }) {
    return (
        <div className="container pos-relative mh-100vh  d-flex flex-column">
            <GoBackHeader backTo={backTo} />
            <Outlet />
        </div>
    );
}

export default GoBackLayout;
