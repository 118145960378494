import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

interface SubscribeReturn {
    challenge: string;
    success: boolean;
    message: string;
}

const subscribe = async (
    login: number, action: 'SUBSCRIPTION_ORANGE_DAILY' | 'SUBSCRIPTION_ORANGE_WEEKLY',
) => {
    try {
        const response = await TaooServerClient.post<SubscribeReturn>(endpoints.auth.resendOtp, {
            login,
            action,
        });

        if (!response || !response?.data) {
            throw new Error('API Error');
        }

        return response.data;
    } catch (error: unknown) {
        console.error(error);
    }
};

interface ValidateReturn {
    id: number;
    status: 'ACTIVE';
    message?: string;
    success?: boolean;
}

const validateNumber = async (
    otp: string, challenge: string,
) => {
    try {
        const response = await TaooServerClient.post<ValidateReturn>(endpoints.auth.otp, {
            otp,
            challenge,
        });

        if (!response || !response?.data) {
            throw new Error('API Error');
        }

        return response.data;
    } catch (error: unknown) {
        console.error(error);
    }
};

export const premiumApi = { subscribe, validateNumber };
