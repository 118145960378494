import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import AppHeader from 'components/headers/app-header';
import InstructionHeader from 'components/headers/instruction-header';
import TAOOHelmet from 'components/helmet/Helmet';

import styles from './index.module.scss';

function NotFound({ user = {} }) {
    const intl = useIntl();

    return (
        <PageTransitionWrapper className="h-100vh d-flex flex-column container">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.not-found' })} />

            {!isEmpty(user) ? <AppHeader /> : <InstructionHeader />}
            <main className="flex-grow-1 d-flex align-items-center justify-content-center">
                <h1 className={styles.notFound}>404</h1>
            </main>
        </PageTransitionWrapper>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(NotFound);
