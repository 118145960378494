import { Close } from 'components/svg';
import { Outlet, useNavigate } from 'react-router-dom';
import './fortuneWheelLayout.scss';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

const FortuneWheelLayout = () => {
    const navigate = useNavigate();
    const lang = useLocale();

    return (
        <div className="fortune-wheel-layout container pos-relative mh-100vh d-flex flex-column">
            <Close onClick={() => {
                navigate(All_PATHS_NAMESPACES[lang].home.path);
            }}/>
            <div className="d-flex flex-grow-1" style={{ marginTop: '2.5rem' }}>
                <Outlet />
            </div>
        </div>
    );
};

export default FortuneWheelLayout;
