export const logColors = {
    info:    '#3498db',
    debug:   '#7f8c8d',
    warn:    '#f39c12',
    success: '#2ecc71',
    error:   '#c0392b',
};

export const logStyles = {
    header: `
        background-color: ${ logColors.info }; 
        color: white; 
        padding: 3px; 
        border-radius: 5px; 
        font-size: 12px; 
        font-weight: bold;
    `,
    text:      'font-size: 12px; font-weight: bold;',
    infoBg:    `background-color: ${ logColors.info };`,
    successBg: `background-color: ${ logColors.success };`,
    errorBg:   `background-color: ${ logColors.error };`,
    warnBg:    `background-color: ${ logColors.warn };`,
    debugBg:   `background-color: ${ logColors.debug };`,
};
