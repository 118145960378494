import { CSSProperties, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PrimaryButton from 'components/form/button/PrimaryButton';
import { LoaderInButtonFillColor } from 'constants/defaults';
import Loader from 'components/loaders/loader';
import { premiumApi } from 'components/premium-screens/premium.service';
import useToast from 'components/widgets/toast/useToast';
import OtpInput from 'components/form/otp-input';

import './premiumRegistrationOtpForm.scss';
import { PremiumBackButton } from '../../back-button/PremiumBackButton';

interface PremiumRegistrationOtpFormProps {
    challenge: string;
    onBack: () => void;
    onFinish: (status?: string) => void;
    style?: CSSProperties;
}

const PremiumRegistrationOtpForm = ({
    challenge, onBack, onFinish, style,
}: PremiumRegistrationOtpFormProps) => {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const [otp, setOtp] = useState('');
    const [isLoading, setLoader] = useState(false);
    const [hasError, setHasError] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        if (!challenge) {
            triggerToast(intl.formatMessage({ id: 'premium.subscription.error' }), 'warn');

            return;
        }

        setHasError(false);
        setLoader(true);
        premiumApi.validateNumber(otp, challenge)
                .then((result) => {
                    if (result && result?.message === 'USER_INVALID_OTP') {
                        setHasError(true);

                        return;
                    }

                    onFinish?.(result.status);
                })
                .finally(() => setLoader(false));
    };

    return (
        <form style={style} className="premium-otp-form" onSubmit={onSubmit}>
            <PremiumBackButton onClick={onBack}/>
            <h1><FormattedMessage id="premium.validate.header" /></h1>

            <p><FormattedMessage id="premium.validate.paragraph" /></p>
            <OtpInput hasError={hasError} value={otp} valueLength={4} onChange={setOtp} />

            <PrimaryButton
                disabled={isLoading}
                type="submit"
            >
                {isLoading ? <Loader fill={LoaderInButtonFillColor} /> : <FormattedMessage id="premium.validate.button" />}
            </PrimaryButton>
        </form>
    );
};

export default PremiumRegistrationOtpForm;
