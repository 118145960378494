import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link, Outlet, useNavigate, useParams,
} from 'react-router-dom';

import GoBackHeader from 'components/headers/go-back-header';
import { ArrowCollapseUp } from 'components/svg';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { QueryParams } from 'services/proxy';
import replacePathParams from 'services/utils/replacePathParams';

import styles from './index.module.scss';

const loadFeatures = () => import('services/framer-motion/domAnimation').then((res) => res.default);

function VoucherSingleLayout() {
    const navigate = useNavigate();
    const { id } = useParams();
    const lang = useLocale();

    const navigateToHistory = () => {
        const path = replacePathParams(All_PATHS_NAMESPACES[lang]['wallet-voucher-details-history'].path, { id });

        navigate(path);
    };

    const [hasJustBeenBought, setHasJustBeenBought] = useState(false);

    useEffect(() => {
        const queryParams = new QueryParams();
        const source = queryParams.getParam('source');

        setHasJustBeenBought(source === 'payment');
    }, []);

    return (
        <div className="container pos-relative mh-100vh d-flex flex-column">
            <div className={`d-flex align-items-center justify-content-between ${ styles.head }`}>
                {!hasJustBeenBought ? (
                    <>
                        <GoBackHeader />

                        {/* <div
                            onClick={navigateToHistory}
                            className={`d-flex align-items-center justify-content-between ${ styles.history }`}
                        >
                            <p>
                                <FormattedMessage id="wallet.voucher-details.see-history" />
                            </p>
                            <ArrowCollapseUp style={{ rotate: '0.25turn' }} />
                        </div> */}
                    </>
                ) : (
                    <AnimatePresence exitBeforeEnter>
                        <LazyMotion features={loadFeatures}>
                            <m.div
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                className=" w-100 mb-3 mt-3"
                            >
                                <Link to={All_PATHS_NAMESPACES[lang].wallet.path} className="btn-base btn-secondary">
                                    <FormattedMessage id="wallet.voucher-details.go-to-wallet" />
                                </Link>
                            </m.div>
                        </LazyMotion>
                    </AnimatePresence>
                )}
            </div>
            <Outlet />
        </div>
    );
}

export default VoucherSingleLayout;
