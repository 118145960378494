import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import AppHeader from 'components/headers/app-header';
import { useSelector } from 'react-redux';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { getCurrentUser } from 'services/utils/currentUser';

function AppLayout() {
    const { pathname } = useLocation();
    const hasStories = useSelector(state => state.appLayoutReducer.shouldAdjustBasedOnStories)
    const user = useGetCurrentUser();

    const isLoggedIn = () => !isEmpty(user) || !isEmpty(getCurrentUser());

    const marginTop = useMemo(() => {
        if (pathname.includes('wallet')) {
            return '100px';
        }

        if (pathname.includes('onboarding')) {
            return '80px';
        }

        if (pathname.endsWith('/store')) {
            return hasStories ? '310px' : '160px';
        }

        return hasStories ? '205px' : '55px';
    }, [pathname, hasStories]);

    return (
        <div 
            style={!isLoggedIn() ? { visibility: 'hidden' } : {}}
            className="container  pos-relative mh-100vh  d-flex flex-column"
        >
            <AppHeader />

            <div className="d-flex flex-grow-1" style={{ marginTop }}>
                <Outlet />
            </div>
        </div>
    );
}

export default AppLayout;
