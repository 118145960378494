import { MAINTENANCE_DEV_ACCESS_KEY, MAINTENANCE_TRIGGERED_KEY } from 'app/maintenance/Maintenance';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { getStorageItem } from 'services/storage';

function PublicOutlet({ user = {}, lang = 'fr' }) {
    const navigate = useNavigate();

    useEffect(() => {
        const maintenanceTriggered = getStorageItem(MAINTENANCE_TRIGGERED_KEY) || false;
        const hasAccess = getStorageItem(MAINTENANCE_DEV_ACCESS_KEY) || false;
        if(maintenanceTriggered && !hasAccess) {
            navigate(All_PATHS_NAMESPACES[lang].maintenance.path);
            
            return;
        }

        if (!isEmpty(user)) {
            navigate(All_PATHS_NAMESPACES[lang].home.path);
        }
    }, [user]);

    return <Outlet />;
}

const mapStateToProps = (state) => ({
    user: state.user,
    lang: state.locale,
});

export default connect(mapStateToProps, null)(PublicOutlet);
