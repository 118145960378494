import { Outlet } from 'react-router-dom';

import InstructionHeader from 'components/headers/instruction-header';

function InstructionLayout() {
    return (
        <div className="container pos-relative mh-100vh  d-flex flex-column">
            <InstructionHeader />
            <Outlet />
        </div>
    );
}

export default InstructionLayout;
