import PrimaryButton from 'components/form/button/PrimaryButton';
import './premiumStatusScreen.scss';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import { FormattedMessage } from 'react-intl';
import PremiumAdvantages from '../advantages/PremiumAdvantages';
import PremiumDetailItem from '../detail-item/PremiumDetailIem';

interface PremiumStatusScreenProps {
    premiumSince: string | null;
    shouldShow: boolean;
    onClose: () => void;
}

const PremiumStatusScreen = ({ premiumSince, shouldShow, onClose }: PremiumStatusScreenProps) => (
    <DraggablePopup
        show={shouldShow}
        shouldShowHandle={false}
        isDraggable={false}
        closeOnBackdropClick
        onClose={onClose}
    >
        <section className="premium-status-screen">
            <h1><FormattedMessage id="premium.overview.title" /></h1>

            <PremiumDetailItem className="premium-status-item" title="Membre depuis:" value={premiumSince ?? 'N/A'} />
            <PremiumDetailItem className="premium-status-item" title="Economies réalisée" />

            <div className="line" />
            <PremiumAdvantages />

            <PrimaryButton className="premium-status-action" type="button" onClick={onClose}>
                Ok
            </PrimaryButton>
        </section>
    </DraggablePopup>
);



export default PremiumStatusScreen;
