import classNames from 'classnames';
import './premiumDetailIem.scss';

interface PremiumDetailItemProps {
    title: string;
    value?: string | number;
    className?: string;
}

const PremiumDetailItem = ({ title, value, className }: PremiumDetailItemProps) => (
    <article className={classNames('premium-detail-item', { [className]: !!className })}>
        <h5>{title}</h5>
        {value && <p>{value}</p>}
    </article>
);

PremiumDetailItem.defaultProps = {
    value:     null,
    className: '',
};

export default PremiumDetailItem;
