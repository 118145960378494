import { useEffect, useState } from 'react';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
        if (showExitPrompt) {
            const e = event || window.event;

            e.preventDefault();

            if (e) {
                e.returnValue = '';
            }

            return '';
        }
    };
};

export function useExitPrompt(enableExitPrompt = false): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
    const [shouldShowExitPrompt, setShouldShowExitPrompt] = useState<boolean>(enableExitPrompt);

    useEffect(() => {
        initBeforeUnLoad(shouldShowExitPrompt);
    }, [shouldShowExitPrompt]);

    return [shouldShowExitPrompt, setShouldShowExitPrompt];
}
