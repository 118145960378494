import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

import TaooServerClient, { generateNewCancelTokenSource } from 'http/axios';
import { getStorageItem, setStorageItem } from 'services/storage';
import { LogManger } from 'services/log';
import { Config } from 'config';

import { clearCacheExceptLocalStorage, forceHardReload } from './cacheUtils';

let interval: ReturnType<typeof setInterval>;

export const BuilderVersionStorageKey = 'build-version';

export default function useUpdateBuildVersion() {
    const [hasNewVersion, setHasNewVersion] = useState(false);
    const [newBuildVersion, setNewBuildVersion] = useState(getStorageItem(BuilderVersionStorageKey) ?? 0);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    useEffect(() => {
        if (Config.isDevelopment) {
            return;
        }

        let source;

        function pingUpdateCheck() {
            setIsStatusLoading(true);

            const currentBuildVersion = getStorageItem(BuilderVersionStorageKey) ?? 0;

            source = generateNewCancelTokenSource();

            LogManger.info('Build Version', 'Checking for new updates...');
            getBuildVersion({ cancelToken: source.token })
                    .then((newVersion) => {
                        if (currentBuildVersion === 0) {
                            LogManger.success('Build Version', 'You have the latest build version.');
                            setStorageItem(BuilderVersionStorageKey, newVersion.id);

                            return;
                        }

                        if (Number(newVersion.id) !== Number(currentBuildVersion)) {
                            setHasNewVersion(true);
                            setNewBuildVersion(Number(newVersion.id));
                            LogManger.error('Build Version', 'New build version available, update to latest version.');

                            return;
                        }

                        LogManger.success('Build Version', 'You have the latest build version.');
                    })
                    .finally(() => {
                        setIsStatusLoading(false);
                    });
        }

        pingUpdateCheck();

        interval = setInterval(() => {
            if (isStatusLoading) {
                return;
            }

            pingUpdateCheck();
        }, 1000 * 60);

        return () => {
            clearInterval(interval);
            source && source.cancel();
        };
    }, []);

    const onUpdateStart = () => {
        LogManger.success('Build Version', 'Updating to latest build version...');

        setIsUpdateLoading(true);
        clearCacheExceptLocalStorage();


        setStorageItem(BuilderVersionStorageKey, newBuildVersion);
        forceHardReload(newBuildVersion);

        setIsUpdateLoading(false);
    };

    const onUpdateCancel = () => {
        setHasNewVersion(false);
        setNewBuildVersion(false);
    };

    return {
        isStatusLoading,
        hasNewVersion,
        onUpdateStart,
        onUpdateCancel,
        isUpdateLoading,
    };
}

interface Version {
    id: string;
    createdAt: string;
}

const getBuildVersion = async (config?: AxiosRequestConfig) => {
    try {
        const response = await TaooServerClient.get<Version>('/v1/public/version', config);

        if (!response || !response?.data) {
            throw new Error('API Error!');
        }

        return response.data;
    } catch (error: unknown) {
        console.error(error);

        return null;
    }
};
