import { CSSProperties, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import PrimaryButton from 'components/form/button/PrimaryButton';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import Dropdown from 'components/widgets/dropdown/Dropdown';
import { premiumApi } from 'components/premium-screens/premium.service';
import useToast from 'components/widgets/toast/useToast';

import './premiumRegistrationForm.scss';
import classNames from 'classnames';
import useGetProfile from 'hooks/profile/useGetProfile';
import { PremiumBackButton } from '../../back-button/PremiumBackButton';

interface PremiumRegistrationFormProps {
    onBack: () => void;
    onFinish: (challenge: string) => void;
    style?: CSSProperties;
}

interface OfferOption { value: 'SUBSCRIPTION_ORANGE_DAILY' | 'SUBSCRIPTION_ORANGE_WEEKLY'; label: string }

const phoneSchema = z
        .string()
        .min(8)
        .max(8);

const PremiumRegistrationForm = ({ onBack, onFinish, style }: PremiumRegistrationFormProps) => {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const { data } = useGetProfile({ shouldFetch: false, ignoreRefetch: true });
    const offerOptions: OfferOption[] = [
        {
            value: 'SUBSCRIPTION_ORANGE_DAILY',
            label: intl.formatMessage({ id: 'premium.subscription.orange-daily' }),
        },
        {
            value: 'SUBSCRIPTION_ORANGE_WEEKLY',
            label: intl.formatMessage({ id: 'premium.subscription.orange-weekly' }),
        },
    ];

    const [phone, setPhone] = useState(data?.phone ? data.phone : '');
    const [isPhoneWrong, setIsPhoneWrong] = useState(false);
    const [offer, setOffer] = useState<OfferOption>(offerOptions[0]);
    const [isOfferWrong, setIsOfferWrong] = useState(false);
    const [isLoading, setLoader] = useState<boolean>(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setIsPhoneWrong(false);
        setIsOfferWrong(false);

        if (!offer) {
            setIsOfferWrong(true);
        }

        if (!phone) {
            setIsPhoneWrong(true);
        }

        if (!offer || !phone) {
            triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

            return;
        }

        const isValid = phoneSchema.safeParse(phone);

        if (!isValid.success) {
            triggerToast(intl.formatMessage({ id: 'error.phone.invalid-type' }), 'warn');
            setIsPhoneWrong(true);

            return;
        }

        setLoader(true);
        premiumApi.subscribe(Number(phone), offer.value)
                .then((result) => {
                    if (!result || result?.message === 'ACTION_FAILED') {
                        triggerToast(intl.formatMessage({ id: 'premium.subscription.error' }), 'error');

                        return;
                    }

                    if (result && result?.message === 'ACTION_INVALID_OPERATOR') {
                        triggerToast(intl.formatMessage({ id: 'premium.subscription.error.wrong-operator' }), 'error');
                        setIsPhoneWrong(true);

                        return;
                    }

                    setIsPhoneWrong(false);
                    setIsOfferWrong(false);

                    if (result && result?.success === true) {
                        onFinish?.(result?.challenge);
                    }
                })
                .finally(() => setLoader(false));
    };

    return (
        <form style={style} className="premium-register-form" onSubmit={onSubmit}>
            <PremiumBackButton onClick={onBack}/>
            <h1><FormattedMessage id="premium.subscription.header" /></h1>

            <p><FormattedMessage id="premium.subscription.paragraph" /></p>
            <Dropdown
                onChange={(option) => {
                    setOffer(option as OfferOption);
                }}
                placeholder="Choisir une option"
                options={offerOptions}
                value={offer}
                className={classNames('premium-register-form-dp', {
                    error: isOfferWrong,
                })}
            />
            <div className={classNames('input-wrapper', {
                error: isPhoneWrong,
            })}>
                <input
                    type="text"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder={intl.formatMessage({ id: 'edit-profile.phone_placeholder' })}
                />
            </div>

            <PrimaryButton
                disabled={isLoading}
                type="submit"
            >
                {isLoading ? <Loader fill={LoaderInButtonFillColor} /> : <FormattedMessage id="premium.subscription.button" />}
            </PrimaryButton>
        </form>
    );
};

export default PremiumRegistrationForm;
