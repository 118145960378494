import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { setLocale } from 'store/locale/action';

function LocalizedLayout({ lang = 'fr', setLocale }) {
    useEffect(() => {
        if (lang === 'ar') {
            document.body.classList.add('rtl');
            document.documentElement.lang = 'ar-TN';
        }

        if (lang === 'en') {
            document.body.classList.remove('rtl');
            document.documentElement.lang = 'en-UK';
        }

        if (lang === 'fr') {
            document.body.classList.remove('rtl');
            document.documentElement.lang = 'fr-FR';
        }

        setLocale(lang);
    }, [lang]);

    return <Outlet />;
}
export default connect(null, { setLocale })(LocalizedLayout);
