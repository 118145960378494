function LoadingScreen() {
    return (
        <div className="suspense">
            <svg viewBox="24.094488 218.26772 496.8298 116.4247" width="496.8298" height="116.4247">
                <g fill="none" stroke="none" fillOpacity="1" strokeDasharray="none" strokeOpacity="1">
                    <g>
                        <g>
                            <path
                                d="M 67.64089 334.6525 L 67.69089 334.6525 C 67.67489 334.6525 67.66189 334.6465 67.64089 334.6465 Z"
                                fill="#231f20"
                            />
                        </g>
                        <g>
                            <path
                                d="M 120.93049 218.26772 L 120.89449 218.26772 L 89.44849 218.26772 L 89.39749 218.26772 L 41.04149 218.26772 C 31.68349 218.26772 24.094488 226.02672 24.094488 235.60072 L 72.50349 235.60072 L 72.50349 317.3137 C 72.50349 326.8737 80.06049 334.6127 89.39749 334.6467 L 89.39749 235.60072 L 89.39749 235.59572 L 120.93049 235.59572 C 130.27049 235.57572 137.83549 227.82672 137.83549 218.26772 Z"
                                fill="#231f20"
                            />
                        </g>
                        <g>
                            <path
                                d="M 329.1038 317.3224 C 307.0438 317.3224 289.1638 299.02142 289.1638 276.45242 C 289.1638 253.90242 307.0438 235.60142 329.1038 235.60142 C 340.1338 235.60142 350.1238 240.18142 357.3438 247.57242 C 364.5738 254.96242 369.0438 265.18142 369.0438 276.45242 C 369.0438 299.02142 351.1638 317.3114 329.1038 317.3224 M 329.1738 218.27142 L 329.1038 218.27142 C 313.4038 218.27142 299.1838 224.78242 288.8838 235.31142 C 278.5938 245.84242 272.2238 260.38242 272.2238 276.45242 C 272.2238 308.59242 297.6938 334.6524 329.1038 334.6524 C 344.8138 334.6414 359.0338 328.1324 369.3238 317.6014 C 379.6238 307.07242 385.9838 292.52142 385.9838 276.45242 C 385.9838 244.34242 360.5638 218.31142 329.1738 218.27142"
                                fill="#231f20"
                            />
                        </g>
                        <g>
                            <path
                                d="M 195.9637 317.3224 C 173.90369 317.3224 156.02369 299.02142 156.02369 276.45242 C 156.02369 253.90242 173.90369 235.60142 195.9637 235.60142 C 206.9837 235.60142 216.9737 240.18142 224.1937 247.57242 C 231.4237 254.96242 235.8937 265.18142 235.8937 276.45242 C 235.8937 299.02142 218.0137 317.3114 195.9637 317.3224 M 252.8037 275.16242 C 252.1337 243.64142 226.9937 218.31142 196.0337 218.27142 L 195.9637 218.27142 C 180.26369 218.27142 166.04369 224.78242 155.74369 235.31142 C 145.45369 245.84242 139.08369 260.38242 139.08369 276.45242 C 139.08369 308.59242 164.55369 334.6524 195.9637 334.6524 C 203.7637 334.6524 211.1937 333.0424 217.9637 330.1324 C 224.7337 327.2214 230.8337 323.0214 235.9737 317.8114 C 236.2037 327.1624 243.6537 334.6624 252.8437 334.6924 L 252.8437 275.13242 Z"
                                fill="#231f20"
                            />
                        </g>
                        <g>
                            <path
                                d="M 464.0443 317.3224 C 441.9833 317.3224 424.1043 299.02142 424.1043 276.45242 C 424.1043 253.90242 441.9833 235.60142 464.0443 235.60142 C 486.1043 235.60142 503.9833 253.90242 503.9833 276.45242 C 503.9833 299.02142 486.1043 317.3114 464.0443 317.3224 M 464.1133 218.27142 L 464.0443 218.27142 C 432.6343 218.27142 407.1633 244.32242 407.1633 276.45242 C 407.1633 308.59242 432.6343 334.6524 464.0443 334.6524 C 495.4643 334.6414 520.9243 308.59242 520.9243 276.45242 C 520.9243 244.34242 495.4933 218.31142 464.1133 218.27142"
                                fill="#231f20"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default LoadingScreen;
