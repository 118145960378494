import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Taoo } from 'components/svg';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { getStorageItem } from 'services/storage';

import './maintenance.scss';

export const MAINTENANCE_DEV_ACCESS_KEY = 'mdak';
export const MAINTENANCE_TRIGGERED_KEY = 'mtk';

const Maintenance = () => {
    const navigate = useNavigate();
    const lang = useLocale();

    useEffect(() => {
        const maintenanceTriggered = getStorageItem(MAINTENANCE_TRIGGERED_KEY) || false;
        const hasAccess = getStorageItem(MAINTENANCE_DEV_ACCESS_KEY) || false;

        if (hasAccess || !maintenanceTriggered) {
            navigate(All_PATHS_NAMESPACES[lang].home.path);
        }
    }, []);

    return (
        <div className="maintenance">
            <Taoo />

            <h2>Maintenance en cours</h2>

            <p>
                Chers utilisateurs,<br/>
                <br/>
                Nous procédons actuellement à des améliorations sur notre site afin de vous offrir une meilleure
                expérience. Notre site sera temporairement indisponible pour quelques heures.<br/>
                <br/>
                Merci de votre compréhension et de votre patience. Nous serons de retour très bientôt!<br/>
                <br/>
                L&apos;équipe TAOO
            </p>
        </div>
    );
};

export default Maintenance;
