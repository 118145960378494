import React from 'react';
import { Outlet } from 'react-router-dom';

const StoryLayout = () => (
    <div className="container  pos-relative mh-100vh  d-flex flex-column p-0">
        <Outlet />
    </div>
);

export default StoryLayout;
