import { Outlet } from 'react-router-dom';

import ProfileHeader from 'components/headers/profile-header';

function ProfileLayout() {
    return (
        <div className="container pos-relative  mh-100vh  d-flex flex-column">
            <ProfileHeader />

            <Outlet />
        </div>
    );
}

export default ProfileLayout;
