import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

function ReferralRegistration() {
    const { referralCode } = useParams();
    const language = useSelector((state) => state.locale);
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('referralCode', referralCode);

        navigate(All_PATHS_NAMESPACES[language].sign_up.path);
    }, []);

    return null;
}

export default ReferralRegistration;
