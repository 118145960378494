import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { getCurrentUser } from 'services/utils/currentUser';
import { getStorageItem } from 'services/storage';
import { CLICKOUT_OFFER_KEY } from 'app/clickout-offer/ClickoutOffer';
import { MAINTENANCE_DEV_ACCESS_KEY, MAINTENANCE_TRIGGERED_KEY } from 'app/maintenance/Maintenance';

function PrivateOutlet() {
    const navigate = useNavigate();
    const user = useGetCurrentUser();
    const lang = useLocale();

    useEffect(() => {
        const maintenanceTriggered = getStorageItem(MAINTENANCE_TRIGGERED_KEY) || false;
        const hasAccess = getStorageItem(MAINTENANCE_DEV_ACCESS_KEY) || false;

        if(maintenanceTriggered && !hasAccess) {
            navigate(All_PATHS_NAMESPACES[lang].maintenance.path);
            
            return;
        }
    }, [])

    useEffect(() => {
        const hasClickoutCode = !!getStorageItem(CLICKOUT_OFFER_KEY);
        const isInHomePage = window.location.pathname === All_PATHS_NAMESPACES[lang].home.path;

        if(hasClickoutCode && isInHomePage) {
            return;
        }

        if (isEmpty(user) && isEmpty(getCurrentUser())) {
            navigate(All_PATHS_NAMESPACES[lang].instruction.path);
        }
    }, [user]);

    return <Outlet />;
}

export default PrivateOutlet;
