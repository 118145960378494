import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLocale from 'hooks/locale/useLocale';
import { setStorageItem } from 'services/storage';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import TaooServerClient from 'http/axios';
import { endpoints } from 'constants/api';

export const QUERY_TOKEN_KEY = 'query-token';

function Query() {
    const { tokenBase64 } = useParams();
    const language = useLocale();
    const navigate = useNavigate();

    useEffect(() => {
        setStorageItem(QUERY_TOKEN_KEY, tokenBase64 ?? 'unknown' );

        const data = JSON.parse(atob(tokenBase64));
        const token = data?.token ?? '';
        const version = data?.version ?? '';
        const route = data?.route ?? '';

        fetchID(token, version, route).then((result) => {
            if (!result || (result?.success !== undefined && !result?.success)) {
                navigate(All_PATHS_NAMESPACES[language].home.path);

                return;
            }

            navigate(All_PATHS_NAMESPACES[language].sign_up.path, {
                state: {
                    queryResult: {
                        data,
                        result,
                    },
                },
            });
        });
    }, []);

    return null;
}

interface FetchResult {
    success?: boolean;
    message?: string;
    error?: string;

    storeId?: number;
    storeAddress?: string;
    storeBrandName?: string;
    storeName?: string;
}

const fetchID = async (token: string, version: string, route: string) => {
    try {
        const response = await TaooServerClient.post<FetchResult>(endpoints.ref.external, {
            token,
            version,
            route,
        });

        if (!response || !response?.data) {
            throw new Error('API Error!');
        }

        return response.data;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export default Query;
