import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

function RedirectToLocale() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(All_PATHS_NAMESPACES.fr.instruction.path);
    }, []);

    return null;
}

export default RedirectToLocale;
