/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-continue */

import { LogManger } from 'services/log';

export const forceHardReload = (build?: string) => {
    LogManger.success('Build Version', 'Forcing hard reload...');

    window.location.href = `${
        window.location.origin +
        window.location.pathname +
        window.location.search +
        (window.location.search ? '&' : '?')
    }reloadTime=${ Date.now().toString() }${ window.location.hash }${ build ? `&build=${ build }` : '' }`;
};

export const clearCacheExceptLocalStorage = () => {
    LogManger.success('Build Version', 'Clearing all cache except localStorage...');
    clearWorkerCache();

    if (window?.caches) {
        // Service worker cache should be cleared with caches.delete()
        window.caches.keys().then(function (names) {
            for (const name of names) caches.delete(name);
        });
    }
};

function clearWorkerCache() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (const registration of registrations) {
                        registration.unregister();
                    }
                });
    }
}
