export class QueryParams {
    constructor() {
        this.getter = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
    }

    getParam(key) {
        if (key) {
            return this.getter?.[key];
        }
    }
}

export const queryParams = new QueryParams();
