import PrimaryButton from 'components/form/button/PrimaryButton';
import './premiumRegistrationFeedback.scss';
import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

interface PremiumRegistrationFeedbackProps {
    type: 'success' | 'failed';
    onFinish: () => void;
    style?: CSSProperties;
}

const PremiumRegistrationFeedback = ({ type, onFinish, style }: PremiumRegistrationFeedbackProps) => (
    <section className="premium-register-fb" style={style}>
        <h1 className={type === 'success' ? 'fb-success' : 'fb-failed'}>
            {type === 'success'
                ? <FormattedMessage id="premium.success.title" />
                : <FormattedMessage id="premium.error.title" />}
        </h1>

        <p><FormattedMessage
            id={type === 'success' ? 'premium.success.message' : 'premium.error.message'}
        /></p>

        <PrimaryButton
            isDanger={type === 'failed'}
            isSuccess={type === 'success'}
            onClick={onFinish}
        >
            Ok
        </PrimaryButton>
    </section>
);

export default PremiumRegistrationFeedback;
