import * as t from "./types";


export const setLocale = (locale = "fr") => dispatch => {

    dispatch({
        type : t.SET_LOCALE,
        payload : locale
    })
}
